import { getBrandConfig, getBrandEnv } from '@/utils/env';
import Vue from 'vue';
/**
 * @typedef {import('./state').userState} userState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get person details state
     * @param {userState} state
     */
    getPersonDetails(state) {
      return state.personDetails;
    },
    /**
     * get user details state
     * @param {userState} state
     */
    getUserDetails(state) {
      return state.userDetails;
    },
    /**
     * get user auth token state
     * @param {userState} state
     */
    getUserAuthToken(state) {
      return state.authTokenDetails;
    },
    /**
     * check user logged in or not
     * @param {userState} state
     */
    isUserLoggedIn(state) {
      return state.authTokenDetails && state.authTokenDetails.accessToken
        ? true
        : false;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getProgramDetails(state) {
      return state.programDetails;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getBrandColor(state) {
      return state.programDetails && state.programDetails.brand && state.programDetails.brand.primaryColor && state.programDetails.brand.primaryColor.length !== 0 ? state.programDetails.brand.primaryColor : getBrandConfig('primaryColor','#5092a3');
    },
    /**
     * get user login with captcha
     * @param {userState} state
     */
    getLoginWithCaptcha(state) {
      return state.loginWithCaptcha;
    },
  },
  mutations: {
    /**
     * update user details state
     * @param {userState} state
     * @param {userDetails} payload
     */
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    /**
     * update user auth token state
     * @param {userState} state
     * @param {authTokenDetails} payload
     */
    setUserAuthToken(state, payload) {
      state.authTokenDetails = payload;
    },
    /**
     * update person details state
     * @param {userState} state
     * @param {personDetails} payload
     */
    setPersonDetails(state, payload) {
      state.personDetails = payload;
    },
    /**
     * update user otp state
     * @param {userState} state
     * @param {string} payload
     */
    updateUserOtp(state, payload) {
      state.userDetails.otp = payload;
    },
    /**
     * update program details
     * @param {userState} state
     * @param {userState['programDetails']} payload
     */
    setProgramDetails(state, payload) {
      state.programDetails = payload;
    },
    /**
     * set user login with captcha
     * @param {userState} state
     */
    setLoginWithCaptcha(state, payload) {
      state.loginWithCaptcha = payload;
    },
  },
  actions: {
    /**
     * user auth login
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} mobileNumber
     * @param {string} captchaToken
     * @returns {Promise<userDetails>}
     */
    login({ commit }, payload) {
      commit('setLoginWithCaptcha', true);
      return new Promise((resolve, reject) => {
        http
          .post('v1/auth/login', {
            connection: 'sms',
            phone: payload.mobileNumber,
            send: 'code',
            clientId: getBrandEnv('clientId'),
            captchaToken: payload.recaptchaToken
          })
          .then(({ data }) => {
            commit('setUserDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          }).finally(() => {
            commit('setLoginWithCaptcha', false);
          });
      });
    },
    /**
     * verify otp
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {userState} param0.state
     * @param {Object} param1
     * @param {string} param1.otp
     * @returns {Promise<authTokenDetails>}
     */
    verifyOtp({ commit, state }, { otp, idNumberLast4, programCode }) {
      return new Promise((resolve, reject) => {
        let payload = {
          connection: 'sms',
          phone: state.userDetails.phone,
          code: otp,
          programCode,
          idNumberLast4,
          clientId: getBrandEnv('clientId')
        };
        http
          .post('v1/auth/verify', payload)
          .then(({ data }) => {
            commit('setUserAuthToken', data);
            http.defaults.headers.common.AUTHORIZATION =
              'Bearer ' + data.accessToken;
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @returns {Promise<personDetails>}
     */
    getPerson({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/person')
          .then(({ data }) => {
            commit('setPersonDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.personId
     * @param {userState['personDetails']} param1.person
     * @returns {Promise<personDetails>}
     */
    updatePerson({ commit }, { personId, person }) {
      return new Promise((resolve, reject) => {
        http
          .patch('v1/person/' + personId, person)
          .then(({ data }) => {
            commit('setPersonDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * logout
     * @param {Object} param0
     * @param {userState} param0.state
     * @param {Function} param0.commit
     * @returns
     */
    logout({ state }) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/auth/logout', {
            refreshToken: state.authTokenDetails.refreshToken,
            clientId: getBrandEnv('clientId'),
            phone: state.personDetails.phone
          })
          .then((data) => {
            // delete tokens
            this.reset();
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get program config
     */
    getProgramConfig({ commit }, programId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/program/' + `${programId}`)
          .then(({ data }) => {
            commit('setProgramDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
