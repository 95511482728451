import Card from './index.vue';
const routes = [
  {
    path: '/card',
    name: 'Card',
    component: Card,
    children: [
      {
        path: 'list',
        name: 'CardList',
        component: () => import('./views/CardList.vue')
      },
      {
        path: 'typeForm',
        name: 'CardTypeForm',
        component: () => import('./views/CardTypeForm.vue')
      },
      {
        path: 'labelForm',
        name: 'CardLabelForm',
        component: () => import('./views/CardLabelForm.vue')
      },
      {
        path: 'spendForm',
        name: 'CardSpendingForm',
        component: () => import('./views/CardSpendingForm.vue')
      },
      {
        path: 'billingAddress',
        name: 'CardBillingAddressForm',
        component: () => import('./views/CardBillingAddressForm.vue')
      },
      {
        path: 'shippingAddress',
        name: 'CardShippingAddressForm',
        component: () => import('./views/CardShippingAddressForm.vue')
      },
      {
        path: 'cardManagement',
        name: 'cardManagement',
        component: () => import('./views/CardManagement.vue')
      },
      {
        path: 'createdSuccess',
        name: 'CreatedSuccess',
        component: () => import('./views/CreatedSuccess.vue')
      },
      {
        path: 'activate',
        name: 'ActivateCardForm',
        component: () => import('./views/ActivateCardForm.vue')
      },
      {
        path: 'activated',
        name: 'CardActivated',
        component: () => import('./views/CardActivated.vue')
      },
      {
        path: 'details',
        name: 'CardDetails',
        component: () => import('./views/CardDetails.vue')
      },
      {
        path: 'replaceCard',
        name: 'ReplaceCardReason',
        component: () => import('./views/ReplaceCardReason.vue')
      },
      {
        path: 'replaceCardDetails',
        name: 'ReplaceCardDetails',
        component: () => import('./views/ReplaceCardDetails.vue')
      },
      {
        path: 'replaceCardAddress',
        name: 'ReplaceCardAddress',
        component: () => import('./views/ReplaceCardAddress.vue')
      }
    ]
  }
];

export default routes;
