import User from './index.vue';

const routes = [
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(/* webpackChunkName: "login" */ './views/Login.vue')
      },
      {
        path: 'loginOtp',
        name: 'LoginOtp',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(/* webpackChunkName: "login-otp" */ './views/LoginOtp.vue')
      },
      {
        path: 'loginSSN',
        name: 'LoginSSN',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(/* webpackChunkName: "login-ssn" */ './views/LoginSSN.vue')
      },
      {
        path: 'loginProgramCode',
        name: 'LoginProgramCode',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "login-programCode" */ './views/LoginProgramCode.vue'
          )
      },
      {
        path: 'loginSuccess',
        name: 'LoginSuccess',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "login-success" */ './views/LoginSuccess.vue'
          )
      }
    ]
  }
];

export default routes;
